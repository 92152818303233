import { Dictionary } from 'ramda'
import {
  useContext,
  ssrRef,
  useRoute,
  readonly,
  computed,
  InjectionKey,
  provide,
  inject,
} from '@nuxtjs/composition-api'
import { LocaleObject } from '@nuxtjs/i18n'
import {
  SiteName,
  GlobalMenus,
  RouteMetas,
  PageMetaMap,
} from './useContent.meta'
import { useLoading } from './ui/useLoading'
import {
  Menu,
  PageMeta,
  ContentLoadOptions,
  ContentPartResult,
  CmsArticle,
  ContentPart,
  SiteBanner,
  ContentLoadContext,
  Banner,
  Robots,
} from '@/types/content'
import { ApiStatus } from '~/types/common'

export const useContentConstructor = () => {
  const menus = ssrRef<Dictionary<Menu[]> | null>(null)
  const siteBanners = ssrRef<SiteBanner[] | null>(null)
  const mainUsps = ssrRef<Banner[] | null>(null)
  const serviceMenus = ssrRef<Menu[] | null>(null)

  const article = ssrRef<CmsArticle | null>(null)
  const pageMeta = ssrRef<PageMeta | null>(null)
  const content = ssrRef<Dictionary<ContentPartResult> | null>(null)
  const notFound = ssrRef<boolean | null>(null)
  const loading = useLoading()

  const { app, $config, i18n, ssrContext, error } = useContext()
  const route = useRoute()

  const resetContent = () => {
    article.value = null
    pageMeta.value = null
    content.value = null
    notFound.value = null
  }

  const resetSiteWideContent = () => {
    menus.value = null
    siteBanners.value = null
    mainUsps.value = null
    serviceMenus.value = null
  }

  const ensureContent = async (options?: ContentLoadOptions) => {
    if (content.value) return

    options = Object.assign({}, options, { ensure: true })
    await loadContent(options)
  }

  const loadRouteContent = async (
    routeName: string,
    options?: ContentLoadOptions
  ): Promise<ContentLoadContext | null> => {
    const request = RouteMetas.get(routeName)?.(route.value)
    // No route meta defined, means no need to fetch specific content
    if (!request) return null

    if (request.selfLoad && !options?.selfLoad) return { request }

    if (typeof request.pageMeta === 'undefined') {
      request.pageMeta = true
    }

    // Ensure global menus load
    if (!menus.value) {
      if (!request.parts) {
        request.parts = [...GlobalMenus.keys()]
      } else {
        request.parts.push(...GlobalMenus.keys())
      }
    }

    // Ensure siteBanners load
    if (!siteBanners.value) {
      if (!request.parts) {
        request.parts = [ContentPart.SiteBanners]
      } else {
        request.parts.push(ContentPart.SiteBanners)
      }
    }
    // Ensure mainUsps load
    if (!mainUsps.value) {
      if (!request.parts) {
        request.parts = [ContentPart.MainUsps]
      } else {
        request.parts.push(ContentPart.MainUsps)
      }
    }

    // Make load serviceMenus once
    if (request.parts?.length && serviceMenus.value?.length) {
      const requestParts = new Set(request.parts)
      if (requestParts.has(ContentPart.ServiceMenus)) {
        requestParts.delete(ContentPart.ServiceMenus)
        request.parts = Array.from(requestParts)
      }
    }

    const result = await app.$api.content.getContent(request, options?.params)
    return {
      request,
      result,
    }
  }

  const loadContent = (options?: ContentLoadOptions) => {
    return loading.scope(async () => {
      if (options?.ensure && content.value) return

      // No route name, refuse to load content
      const routeName = route.value.name?.split('___')[0]
      if (!routeName) return

      // Try to load content for route
      let context = await loadRouteContent(routeName, options)
      if (!context) {
        // Use fallback when there are no content request for some pages
        context = await loadRouteContent('__fallback', options)
      }
      // If it's a self load content, return directly when try global loading
      if (context?.request.selfLoad && !options?.selfLoad) return

      let result = context?.result

      const firstFailed = !result || result.status !== ApiStatus.Ok
      notFound.value = result?.status === ApiStatus.NotFound

      const isWildcardPage = routeName === 'all'
      if (process.server && ssrContext && notFound.value && isWildcardPage) {
        ssrContext.res.statusCode = ApiStatus.NotFound
        error({
          statusCode: ApiStatus.NotFound,
        })
      }
      // Only use first request Article
      article.value = result?.article ?? {}

      // First failure fallback to ensure global menus loaded
      if (firstFailed && !menus.value) {
        context = await loadRouteContent('__fallback', options)
        result = context?.result
      }

      if (!result || result.status !== ApiStatus.Ok) return

      if (result.parts) {
        const parts = result.parts as Dictionary<ContentPartResult>

        // Extract global menus result
        if (!menus.value) {
          const tempMenus: Dictionary<Menu[]> = {}
          GlobalMenus.forEach((part) => {
            const menuResult = parts[part] as Menu[]
            if (menuResult) {
              tempMenus[part] = menuResult
              delete parts[part]
            }
          })

          menus.value = tempMenus
        }

        // Extract global siteBanners result
        if (!siteBanners.value) {
          siteBanners.value = parts[ContentPart.SiteBanners] as SiteBanner[]
          delete parts[ContentPart.SiteBanners]
        }

        // Extract serviceMenus result
        if (!serviceMenus.value) {
          serviceMenus.value = parts[ContentPart.ServiceMenus] as Menu[]
          delete parts[ContentPart.ServiceMenus]
        }

        // Extract mainUsps result
        if (!mainUsps.value) {
          mainUsps.value = parts[ContentPart.MainUsps] as Banner[]
          delete parts[ContentPart.MainUsps]
        }
      }

      // Extract rest content parts
      content.value = result.parts ?? {}

      // Extract page meta
      if (result.pageMeta) {
        pageMeta.value = result.pageMeta
      }

      const robotsValue = [
        article.value?.robotsNoIndex ? Robots.NoIndex : '',
        article.value?.robotsNoFollow ? Robots.NoFollow : '',
      ]
        .filter((item) => !!item)
        .join(',')

      if (robotsValue) {
        setPageMeta({
          robots: robotsValue,
        })
      }
    })
  }

  const setPageMeta = (meta: PageMeta) => {
    pageMeta.value = {
      ...pageMeta.value,
      ...meta
    }
  }

  const toMeta = (key: string, val: string, needHid = true) => {
    return Object.assign(
      needHid
        ? {
            hid: key,
          }
        : {},
      /^(og:)/.test(key) ? { property: key } : { name: key },
      /^(twitter:card:)/.test(key) ? { content: 'summary_large_image' } : { content: val },
    )
  }

  const toHtmlMeta = (meta: PageMeta | null) => {
    if (!meta) return {}

    meta.name = SiteName
    meta.type ||= 'website'
    meta.link = `${$config.DOMAIN}${route.value.path}`

    const currentLocaleIso = i18n.localeProperties.iso
    meta.locale = currentLocaleIso
    meta.alternateLocales = (i18n.locales as LocaleObject[]).reduce(
      (prev, current) => {
        if (current.iso && current.iso !== currentLocaleIso) {
          prev.push(current.iso)
        }
        return prev
      },
      [] as string[]
    )

    const metas: ReturnType<typeof toMeta>[] = []
    for (const key in meta) {
      const value = meta[key] as PageMeta[keyof PageMeta]
      if (!value) continue

      const metaNames = PageMetaMap.get(key)
      if (!metaNames?.length) continue

      if (Array.isArray(value)) {
        metaNames.forEach((metaKey) => {
          metas.push(...value.map((val) => toMeta(metaKey, val, false)))
        })
      } else {
        metaNames.forEach((metaKey) => {
          metas.push(toMeta(metaKey, value))
        })
      }
    }

    return {
      title: `${meta.title} - ${SiteName}`,
      htmlAttrs: {
        lang: app.i18n.locale,
      },
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: meta.link,
        },
      ],
      meta: metas,
    }
  }

  const htmlMeta = computed(() => {
    return toHtmlMeta(pageMeta.value)
  })

  return {
    menus: readonly(menus),
    serviceMenus: readonly(serviceMenus),
    siteBanners: readonly(siteBanners),
    mainUsps: readonly(mainUsps),
    article: readonly(article),
    content,
    htmlMeta,
    loading: loading.value,

    resetContent,
    resetSiteWideContent,
    ensureContent,
    setPageMeta,
  }
}

export const contentKey: InjectionKey<
  ReturnType<typeof useContentConstructor>
> = Symbol('Provider:Content')

export const provideContent = () => {
  const result = useContentConstructor()

  provide(contentKey, result)
}

export const useContent = () => {
  const result = inject(contentKey)

  if (!result) {
    throw new Error('content provider not set')
  }

  return result
}
