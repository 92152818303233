import { useContext, ref, computed } from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { useEvents } from './framework/useEvents'
import { AuthEvents, useAuth } from './useAuth'
import { useConfig } from './useConfig'
import { Customer, ChangePasswordParams } from '~/types/customer'

const customer = ref<Customer | null>(null)
const logisticStoreId = ref<number | null>(null)
const isSubscribed = ref<boolean>(false)
const updateCustomerLoading = useLoading()
const getCustomerLoading = useLoading()
const setDefaultStoreLoading = useLoading()
const changePasswordLoading = useLoading()

export const useCustomer = () => {
  const { app } = useContext()
  const { on } = useEvents()
  const { config } = useConfig()
  const { authed, setToken, setPreferLanguage } = useAuth()

  // Ensure listen to storeChanged event
  on(
    AuthEvents.Logout,
    () => {
      customer.value = null
    },
    'customer'
  )

  const ensureCustomer = async () => {
    if (!process.client || customer.value) return

    await getCustomer(true)
  }

  const getCustomer = async (ensure = false) => {
    if (!authed.value) return

    await getCustomerLoading.scope(async () => {
      if (ensure && customer.value) return
      await doGetCustomer()
    })
  }

  const doGetCustomer = async () => {
    customer.value = await app.$api.customer.getCustomer()
  }

  const updateCustomer = (customer: Customer) => {
    return updateCustomerLoading.scope(async () => {
      const token = await app.$api.customer.updateCustomer(customer)
      if (token) {
        await doGetCustomer()
        setToken(token)
        setPreferLanguage(token)
      }

      return !!token
    })
  }

  // setSubscribe
  const setSubscribe = (val: boolean) => {
    isSubscribed.value = val
  }

  // Loyalty
  const isLoyaltyCustomer = computed(() => {
    // Check KVK number, see comment: https://dev.azure.com/firstfocusim/www.bouwmaat.nl/_workitems/edit/3881
    if (!customer.value?.kvkNumber) return false

    // Check customer group
    const code = customer.value?.groupCode
    const loyaltyCodes = config.value?.loyaltyCustomerCodes
    if (!code || !loyaltyCodes) return false

    return loyaltyCodes.some((pattern) => code.includes(pattern))
  })

  // Store
  // const ensureLogicsticStore = async () => {
  //   const store = await app.$api.customer.getLogicsticStore()
  //   logisticStoreId.value = store?.id ?? null
  // }

  const setDefaultStore = async (storeId: number) => {
    let success = false
    await setDefaultStoreLoading.scope(async () => {
      success = await app.$api.customer.setDefaultStore(storeId)
      if (success) {
        await doGetCustomer()
      }
    })
    return success
  }

  const changePassword = (changePassword: ChangePasswordParams) => {
    return changePasswordLoading.scope(async () => {
      const response = await app.$api.customer.changePassword(changePassword)
      return response.data
    })
  }

  return {
    customer,
    setDefaultStoreLoading: setDefaultStoreLoading.value,
    getCustomerLoading: getCustomerLoading.value,
    updateCustomerLoading: updateCustomerLoading.value,
    changePasswordLoading: changePasswordLoading.value,
    isLoyaltyCustomer,
    isSubscribed,
    ensureCustomer,
    getCustomer,
    updateCustomer,
    setSubscribe,

    logisticStoreId,
    setDefaultStore,
    // ensureLogicsticStore,
    changePassword,
  }
}
