import {
  useContext,
  ssrRef,
  readonly,
  inject,
  InjectionKey,
  provide,
} from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { GlobalConfig } from '~/types/common'

export const useConfigConstructor = () => {
  const { app } = useContext()
  const config = ssrRef<GlobalConfig | null>(null)
  const loading = useLoading()

  const ensureConfig = async () => {
    if (config.value) return

    await loading.scope(async () => {
      if (config.value) return

      config.value = await app.$api.config.getConfig()
    })
  }

  return {
    loading: loading.value,
    config: readonly(config),
    ensureConfig,
  }
}

export const configKey: InjectionKey<ReturnType<typeof useConfigConstructor>> =
  Symbol('Provider:GlobalConfig')

export const provideConfig = () => {
  const result = useConfigConstructor()

  provide(configKey, result)
}

export const useConfig = () => {
  const result = inject(configKey)

  if (!result) {
    throw new Error('global config provider not set')
  }

  return result
}
