import { clone } from 'ramda'
import { useConfig } from './useConfig'
import { ProductList } from './product/useProduct.e'
import { encodeName } from '~/lib/utilities'
import { Cart, CartLine, CartPayment } from '~/types/cart'
import { GtmInjected } from '~/types/gtm'
import { Order } from '~/types/order'
import { Product } from '~/types/product'
import { WebNode } from '~/types/webNode'

export const ITEM_LIST_MAP = {
  plp: {
    id: 'plp',
    name: 'Product List Page',
  },
  srp: {
    id: 'srp',
    name: 'Search Results Page',
  },
  blp: {
    id: 'blp',
    name: 'Brand List Page',
  },
  pdp: {
    id: 'pdp',
    name: 'Product Detail Page',
  },
  recently_viewed_list: {
    id: 'recently_viewed_list',
    name: 'Recently Viewed List',
  },
}

export const useDatalayer = ($gtm: GtmInjected, root?: WebNode | null) => {
  const init = async () => {
    const { config, ensureConfig } = useConfig()
    await ensureConfig()

    const gtmId = config.value?.gtmSetting
    const enabled = config.value?.gtmEnabled

    if (enabled && gtmId) {
      $gtm.init(gtmId)
    }
  }

  const clearEcommerce = () => {
    $gtm.push({
      ecommerce: null,
    })
  }

  // handle get all levels category
  const getWebNodeAncestors = (
    nodes: WebNode[],
    id: number,
    ancestors: WebNode[] = []
  ): WebNode[] => {
    for (const node of nodes) {
      if (node.id === id) {
        return ancestors
      }
      if (node?.children?.length) {
        const result = getWebNodeAncestors(node.children, id, [
          ...ancestors,
          node,
        ])
        if (result.length) {
          return result
        }
      }
    }
    return []
  }

  //
  // Product
  //
  const convertProductCategories = (node?: WebNode) => {
    if (!node) return {}
    const nodes = clone(root?.children) ?? []
    const ancestors = getWebNodeAncestors(nodes, node.id)
    const categories =
      [...ancestors, node]?.map((item) => encodeName(item.name)) ?? []
    return categories.reduce(
      (result, current, index) => ({
        ...result,
        [index === 0 ? 'item_category' : `item_category${index + 1}`]: current,
      }),
      {}
    )
  }

  const convertProductList = (list?: ProductList) => {
    return {
      item_list_name: list?.name,
      item_list_id: list?.id,
    }
  }

  const convertProductBase = (product: Product) => {
    return {
      item_id: product.id,
      item_name: encodeName(product.name),
      item_brand: encodeName(product.brand?.name),
      ...convertProductCategories(product.webNode),
    }
  }

  const convertProduct = (list: ProductList, product: Product) => {
    return {
      ...convertProductBase(product),
      ...convertProductList(list),
      price: product.priceInclTax,
      quantity: 1,
    }
  }

  const onSearch = (term: string) => {
    clearEcommerce()
    $gtm.push({
      event: 'search',
      ecommerce: {
        search_term: term,
      },
    })
  }

  const onViewProductList = (list: ProductList, products: Product[]) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_item_list',
      ...convertProductList(list),
      ecommerce: {
        items: products.map((product, index) => ({
          ...convertProduct(list, product),
          index: index + 1,
          quantity: 1,
        })),
      },
    })
  }

  const onClickProduct = (
    list: ProductList,
    product: Product,
    index: Number
  ) => {
    clearEcommerce()
    $gtm.push({
      event: 'select_item',
      ...convertProductList(list),
      ecommerce: {
        items: [
          {
            ...convertProduct(list, product),
            index,
            quantity: 1,
          },
        ],
      },
    })
  }

  const onViewProductDetails = (list: ProductList, product: Product) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_item',
      ecommerce: {
        items: [
          {
            ...convertProduct(list, product),
            quantity: 1,
          },
        ],
      },
    })
  }

  //
  // Cart
  //
  const convertCartLine = (line: CartLine) => {
    return {
      ...convertProductBase(line.product as Product),
      ...convertProductList({
        id: line.source?.listId,
        name: line.source?.listName,
      } as ProductList),
      price: line.priceInclTax,
      quantity: line.quantity,
    }
  }

  const onAddProducts = (lines: CartLine[]) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_to_cart',
      ecommerce: {
        items: lines.map((line) => convertCartLine(line)),
      },
    })
  }

  const onRemoveProducts = (lines: CartLine[]) => {
    clearEcommerce()
    $gtm.push({
      event: 'remove_from_cart',
      ecommerce: {
        items: lines.map((line) => convertCartLine(line)),
      },
    })
  }

  const onViewCart = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'view_cart',
      ecommerce: {
        value: cart.totalInclTax,
        items: cart.lines.map((line) => ({
          ...convertCartLine(line),
          discount: line.discountInclTax,

          currency: 'EUR',
          affiliation: '',
          coupon: '',
          location_id: '',
        })),

        currency: 'EUR',
      },
    })
  }

  //
  // Checkout
  //

  const convertCheckout = (cart: Cart) => {
    return {
      value: cart.totalInclTax,
      items: cart.lines.map((line) => ({
        ...convertCartLine(line),

        affiliation: '',
        coupon: '',
        currency: 'EUR',
      })),

      currency: 'EUR',
      coupon: '',
    }
  }

  const onBeginCheckout = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'begin_checkout',
      ecommerce: convertCheckout(cart),
    })
  }

  const onSetShipment = (cart: Cart) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_shipping_info',
      ecommerce: {
        ...convertCheckout(cart),

        shipping_tier: cart.shippingMethodType, // Todo: shipping carrier?
      },
    })
  }

  const onSetPayment = (cart: Cart, payment: CartPayment) => {
    clearEcommerce()
    $gtm.push({
      event: 'add_payment_info',
      ecommerce: {
        ...convertCheckout(cart),

        payment_type: payment.method,
      },
    })
  }

  const onPaid = (order: Order, isNewCustomer: boolean) => {
    clearEcommerce()
    $gtm.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: order.orderNumber,
        affiliation: encodeName(order.store?.name) ?? order.store?.id,
        value: order.totalInclTax?.toFixed(2),
        tax: (order.totalInclTax - order.totalExclTax).toFixed(2),
        shipping: order.shippingPrice?.toFixed(2),
        coupon:
          order.voucherTotal > 0 ? order.voucherTotal.toFixed(2) : undefined,
        items: order.orderLines.map((line) => ({
          ...convertProductBase(line.product),
          ...convertProductList({
            id: line.source?.listId,
            name: line.source?.listName,
          } as ProductList),
          price: line.priceInclTax?.toFixed(2),
          quantity: line.quantity,
        })),

        currency: 'EUR',
        new_customer: isNewCustomer,
        email: order.customer?.email || 'geen email adres bekend',
        phone_number:
          order.customer?.phoneNumber || 'geen telefoonnummer bekend',
      },
    })
  }

  return {
    init,

    onSearch,
    onViewProductList,
    onClickProduct,
    onViewProductDetails,

    onAddProducts,
    onRemoveProducts,
    onViewCart,

    onBeginCheckout,
    onSetShipment,
    onSetPayment,
    onPaid,
  }
}
